<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    entity-type="municipality"
    locale-section="pages.municipalities"
    field-section="municipalities"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "MunicipalityDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: municipality
        municipality
        number
        county {
          id
        }
      `,
      querySelects: /* GraphQL */ `
        county: counties {
          id
          county
          country {
            country
          }
        }
      `,
      inputAttributes: {
        municipality: { required: true },
        county: { required: true }
      }
    };
  }
};
</script>
